@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fsFadeIn {
  from {
    opacity: .2;
    transform: scale(1);
  }
  to {
    opacity: 1;
    transform: scale(1.1)
  }
}
@keyframes fsFadeOut {
  from {
    opacity: 1;
    transform: scale(1.1);
  }
  to {
    opacity: .2;
    transform: scale(1);
  }
}

@keyframes pulse {
  from {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  to {
    transform: scale(0.85);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideIn {
  from {
    position: fixed;
    left: -110vw;
    top: 0;
  }
  to {
    position: fixed;
    left: 0;
    top: 0;
  }
}
