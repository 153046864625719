@import url(https://fonts.googleapis.com/css?family=Baloo+Da|Nosifer|Syncopate);
@-webkit-keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fsFadeIn {
  from {
    opacity: .2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1)
  }
}
@keyframes fsFadeIn {
  from {
    opacity: .2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1)
  }
}
@-webkit-keyframes fsFadeOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    opacity: .2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes fsFadeOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    opacity: .2;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  to {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes slideIn {
  from {
    position: fixed;
    left: -110vw;
    top: 0;
  }
  to {
    position: fixed;
    left: 0;
    top: 0;
  }
}

@keyframes slideIn {
  from {
    position: fixed;
    left: -110vw;
    top: 0;
  }
  to {
    position: fixed;
    left: 0;
    top: 0;
  }
}

/* IMPORTS */

/* VARIABLES */
:root {
  --white: #fefefe;
  --blue: #255C69;
  --dark: #424242;
  --red: #AA4639;
  --green: #2A7F41;
  --gold: rgb(199, 138, 77);
  --primary-font: "Baloo Da", cursive;
  --secondary-font: "Syncopate", sans-serif;
  --dead-font: "Nosifer", cursive;
  /** POISON COUNTER */
  --infect-color: #F52030;
  --poison-color: #7aa82c;
  --border-color: #343434;
  --border-radius: 5px;
}

/* ELEMENTS */
a:hover {
  text-decoration: none;
}
div#background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}
body {
  background-color: #424242;
  background-color: var(--dark);
  box-sizing: border-box;
  font-family: "Baloo Da", cursive;
  font-family: var(--primary-font);
  color: #fefefe;
  color: var(--white);
  text-shadow: 1px 1px #424242;
  text-shadow: 1px 1px var(--dark);
  font-size: 43px;
}
button:focus {
  outline: none !important;
}
input[type="text"]:focus,
i.active {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
  -webkit-transition: all();
  transition: all();
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 150ms;
          animation-duration: 150ms;
}
legend {
  color: #fefefe;
  color: var(--white);
}
li {
  list-style-type: none !important;
}
textarea:focus {
  box-shadow: 0 0 5px #255C69;
  box-shadow: 0 0 5px var(--blue);
  padding: 3px 0px 3px 3px;
  border: 1px solid #255C69;
  border: 1px solid var(--blue);
}

/* IDs */
#backgrounds-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  z-index: 100;
  background-color: #424242;
  background-color: var(--dark);
  opacity: 0.99;
  min-height: 100vh;
  min-width: 100vw;
}
#infect-toggle-button {
  grid-area: infect;
}
#poison-toggle-button {
  grid-area: poison;
}
#flip-button,
#roll-button {
  height: 5em;
  width: 5em;
  align-self: center;
  justify-self: center;
  background-color: transparent;
  border: none;
}
#flip-button {
  grid-area: flip;
  border-radius: 100%;
}
#fullscreen-prompt {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 110;
  background-color: transparent;
  color: #fefefe;
  color: var(--white);
  padding: 8px;
  -webkit-animation: fsFadeOut 350ms ease-in-out forwards;
          animation: fsFadeOut 350ms ease-in-out forwards;
}
#fullscreen-prompt:hover {
  cursor: pointer;
  -webkit-animation: fsFadeIn 350ms ease-in-out forwards;
          animation: fsFadeIn 350ms ease-in-out forwards;
}
#menu_dice-and-damage {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(9, 1fr);
  grid-template-areas:
    "flip flip flip energy energy energy roll roll roll"
    "flip flip flip infect infect infect roll roll roll"
    "flip flip flip poison poison poison roll roll roll";
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
}
#menu-controls {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "controls"
    "reset";
  grid-gap: 12px;
  justify-content: center;
  align-content: center;
}
#menu-footer {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(10, 1fr);
  grid-template-areas: "auth auth auth auth help feedback feedback feedback feedback feedback";
  width: 100%;
  height: 60px;
}
#help {
  grid-area: help;
  position: relative;
  top: 19px;
  cursor: pointer;
}
#auth-button {
  grid-area: auth;
  position: relative;
  top: 19px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  cursor: pointer;
}
#auth-button > span, #show-feedback-button > span {
  font-size: 14px;
  position: relative;
  top: 3px;
}
#show-feedback-button {
  grid-area: feedback;
  position: relative;
  top: 19px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  cursor: pointer;
}
#menu_dice-and-damage {
  grid-area: controls;
}
.menuDiv-active,
#msgOutputWrapper {
  font-family: "Syncopate", sans-serif;
  font-family: var(--secondary-font);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: #424242;
  background-color: var(--dark);
  font-size: 18px;
}
#msgOutputWrapper {
  line-height: 32px;
}
#resetLifeButton, #resetWins, #poison-toggle-button.btn-primary, #infect-toggle-button.btn-primary {
  background-color: #255C69;
  background-color: var(--blue);
  border-color: #255C69;
  border-color: var(--blue);
  color: #fefefe;
  color: var(--white);
}
#poison-toggle-button.btn-danger, #infect-toggle-button.btn-danger {
  background-color: #AA4639;
  background-color: var(--red);
  border-color: #AA4639;
  border-color: var(--red);
}
#feedback-form-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  font-size: .45em;
  z-index: 100 !important;
  font-family: "Baloo Da", cursive;
  font-family: var(--primary-font);
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background-color: #AA4639;
  padding: 8px;
  box-sizing: border-box;
}
#feedback-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
#text-inputs {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  flex-basis: 400px;
  align-items: center;
}
.input {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
}
.input > label {
  font-weight: 400;
}
#radio-input-legend {
  text-align: center;
}
#name-input, #email-input, #feedback-input {
  max-width: 100%;
  padding: 8px;
  margin: 4px 8px;
  color: #424242;
  color: var(--dark);
  background-color: #fefefe;
  background-color: var(--white);
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  border-radius: 8px;
}
#menu-header {
  color: #255C69;
  color: var(--blue);
  padding: 12px;
}
#menuWrapper {
  color: #fefefe;
  color: var(--white);
  text-shadow: #fefefe;
  text-shadow: var(--white);
  background-image: url(/static/media/d20-512.645ec8ef.png);
  background-size: contain;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}
#name-form {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #424242;
  background-color: var(--dark);
  z-index: 80;
  opacity: .97;
}
#player_0,
#player_1 {
  height: 50vh;
  max-height: 50vh;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}
#players-component-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: "top" "bottom";
  justify-content: center;
  align-items: center;
  z-index: 0;
}
#player_0 {
  grid-area: bottom;
}
#player_1 {
  grid-area: top;
}
#reset {
  grid-area: reset;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  width: 100vw;
}
#roll-button {
  grid-area: roll;
}
#upload-button, #upload-icon, #feedback-submit-button {
  color: #424242;
  color: var(--dark);
}
#upload-button, #feedback-submit-button {
  height: 45px;
  width: 180px;
  position: fixed;
  bottom: 8px;
  right: 8px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
#upload-icon {
  position: relative;
  right: 7px;
  bottom: 5px;
}
#feedback-icon {
  position: relative;
  right: 11px;
  bottom: 5px;
}
.feedback-button-text {
  position: relative;
  right: 4px;
  bottom: -2px;
}
#walkthroughButton {
  display: none;
  font-size: 0.75em;
}
#walkthroughButton:hover {
  cursor: pointer;
}

/* CLASSES */
.animated-fade-in {
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-duration: 350ms;
          animation-duration: 350ms;
}
#backgrounds-wrapper {
  max-width: 100vw;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.backgrounds-background {
  flex-basis: 1 1 100px;
  max-width: 90%;
  border-radius: 8px;
  margin: .25em 0;
}
.firebaseui-idp-anonymous {
  background-color: #424242;
  background-color: var(--dark);
}
#name-form > .form-group {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr) 4fr;
  grid-template-areas: "."
                        "label"
                        "input"
                        "."
}
#name-form > .form-group > label {
  grid-area: label;
  align-self: center;
  justify-self: center;
}
#name-form > .form-group > input {
  grid-area: input;
  max-width: 90%;
  align-self: center;
  justify-self: center;
}
.glyphicon-upload:before {
  position: relative;
  bottom: 6px;
  right: 7px;
}
.menuDiv-active {
  -webkit-animation-name: slideIn;
          animation-name: slideIn;
  -webkit-animation-duration: 150ms;
          animation-duration: 150ms;
  -webkit-transition: all;
  transition: all;
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
}
.menuDiv-inactive {
  -webkit-transition: all;
  transition: all;
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
  position: fixed;
  width: 0;
  top: 0;
  left: -200vw;
}
#playerWrapperInner_0, #playerWrapperInner_1 {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(10, 10%);
  grid-template-columns: repeat(10, 10%);
  grid-template-areas:
    ". name name name name name name name name ."
    ". name name name name name name name name ."
    "poison-counter . . . . . . . . infect-counter"
    "poison-counter . . . . . . . . infect-counter"
    "poison-counter . . . . . . . . infect-counter"
    "poison-counter . . . . . . . . infect-counter"
    "poison-counter . controls controls controls controls controls controls . infect-counter"
    "poison-counter . controls controls controls controls controls controls . infect-counter"
    ". . . . scoreboard scoreboard scoreboard scoreboard scoreboard scoreboard"
    ". . . . scoreboard scoreboard scoreboard scoreboard scoreboard scoreboard";
  justify-content: center;
  align-items: center;
}
.playerWrapperOuter {
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}
.player_header {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: baseline;
  grid-area: name;
  color: rgb(199, 138, 77);
  color: var(--gold);
}
.controls {
  grid-area: controls;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
}
#poison-counter-decrement_1, #poison-counter-decrement_2, #life-increment_1, #life-increment_2, #infect-counter-decrement_1, #infect-counter-decrement_2 {
  color: #2A7F41;
  color: var(--green);
}
#poison-counter-increment_1, #poison-counter-increment_2, #life-decrement_1, #life-decrement_2, #infect-counter-increment_1, #infect-counter-increment_2 {
  color: #AA4639;
  color: var(--red);
}
.life_total_button_group {
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.life_total-critical {
  color: #AA4639;
  color: var(--red);
  font-family: "Nosifer", cursive;
  font-family: var(--dead-font);
}
.scoreboard {
  grid-area: scoreboard;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 100%;
}
.scoreboard > * {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  font-size: 1.25em;
  height: 1em;
}
.wins {
  grid-area: wins;
}
.losses {
  grid-area: losses;
}
.scoreboard_image {
  background-color: #fefefe;
  background-color: var(--white);
  opacity: 0.8;
  padding: 2px;
  border-radius: 8px;
}
.upload-button-text {
  position: relative;
  height: 100%;
  bottom: 13px;
  left: 2px;
}
.wins > img,
.losses > img {
  height: 0.8em;
  width: 0.8em;
}
.playerScore {
  padding: 0 0.2em;
}
#back-arrow-button {
  position: fixed;
  height: 45px;
  position: fixed;
  bottom: 8px;
  left: 8px;
  background-color: transparent;
  border: none;
}
.feedback-back-arrow-text {
  color: rgb(199, 138, 77);
  color: var(--gold);
  position: relative;
  height: 100%;
  bottom: 5px;
  left: -1px;
}
div.input.radio-input {
  display: flex;
  flex-flow: row-wrap;
  justify-content: space-evenly;
  align-items: center;
}
.radioDiv {
  white-space: nowrap;
  font-family: "Syncopate", sans-serif;
  font-family: var(--secondary-font);
  font-size: 12px;
}
input[type='radio'] {
  position: relative;
  top: 3px;
  right: 3px;
}
.poison-counter-1.active, .poison-counter-2.active {
  opacity: .9 !important;
}
/** MEDIA QUERIES */
@media screen and (orientation: landscape) {
  #players-component-wrapper {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "bottom top";
  }
  #player_0,
  #player_1 {
    height: 100vh;
    max-height: 100vh;
    width: 50vw;
    max-width: 50vw;
  }
}

@media screen and (orientation: portrait) {
  #player_1 {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
}

@media screen and (orientation: portrait) and (max-width: 575px) {
  .no-small-screens {
    display: none;
  }
}

@media screen and (orientation: landscape) and (max-height: 400px) {
  #menu-header {
    display: none;
  }
}
@media all and (display-mode: standalone) {
  #fullscreen-prompt {
    right: -60px
  }
}
@media all and (display-mode: fullscreen) {
  #fullscreen-prompt {
    right: -60px
  }
}
